<template>
  <div
    :role="`level-${level}`"
    :class="[
      classes,
      {
        'tw-inline-block': inline,
        'tw-pb-1 tw-pt-3': !inline,
      },
    ]"
    class="tw-font-bold tw-font-display">
    <slot />
  </div>
</template>

<script>
import { headingLevel } from '@/service/tailwind/variants/headingLevel';
import { useTailwindProp } from '@/service/tailwind/useTailwindProp';

const levelProp = useTailwindProp(headingLevel);

export default {
  name: 'Heading',

  props: {
    level: levelProp.prop,

    inline: {
      type: Boolean,
    },
  },

  computed: {
    /**
     * @returns {string|string[]}
     */
    classes() {
      return levelProp.options[this.level];
    },
  },
};
</script>

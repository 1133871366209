<template>
  <p class="tw-pb-2">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'Paragraph',
};
</script>

/* eslint-disable no-magic-numbers */
import { defineVariant } from '@/service/tailwind/defineVariant';

export const headingLevel = defineVariant(
  'headingLevel',
  {
    1: ['tw-text-4xl'],
    2: ['tw-text-2xl'],
    3: ['tw-text-xl'],
    4: ['tw-text-lg'],
  },
  {
    type: [String, Number],
    default: 1,
  },
);

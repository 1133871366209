<script>
import DelayedTransitionGroup from '@/components/DelayedTransitionGroup';

let componentInstance = 0;

export default {
  name: 'SkeletonLoader',
  functional: true,
  components: { DelayedTransitionGroup },

  props: {
    centered: {
      type: Boolean,
    },
  },

  render(createElement, context) {
    componentInstance++;

    if (!context.children) {
      context.children = [createElement('div')];
    }

    context.children = context.children.map((child, index) => {
      // Create an unique key for each child, based on component instance, parent id and child index.
      // eslint-disable-next-line no-underscore-dangle
      child.key = `${componentInstance}-${context.parent._uid}-${index}`;

      return child;
    });

    const classes = [
      'skeleton-loader',
      'select-none',
    ];

    if (context.props.centered) {
      classes.push('skeleton-loader--centered');
    }

    return createElement(
      DelayedTransitionGroup,
      {
        ...context.data,
        props: {
          ...context.data.props,
          appear: true,
          tag: 'div',
          name: 'fade',
        },
        class: classes.join(' '),
      },
      context.children,
    );
  },
};
</script>

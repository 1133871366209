/* eslint-disable jsdoc/no-undefined-types */

/**
 * @param {string} name
 * @param {TailwindVariantOptions} options
 * @param {Object} propOptions - Additional options to add to prop definition.
 *
 * @returns {TailwindVariant}
 */
export function defineVariant(name, options, propOptions = {}) {
  return {
    name,
    options,
    propOptions,
  };
}

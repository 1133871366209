<template>
  <div
    class="tw-rounded-xl tw-shadow"
    :class="variantClasses">
    <div class="md:tw-pb-9 md:tw-pt-7 md:tw-px-9 tw-overflow-hidden tw-pb-5 tw-pt-3 tw-px-5">
      <Skeleton
        v-if="title"
        :loading="loading"
        :centered="centered">
        <template #default>
          <Heading level="2">
            {{ $te(title) ? $t(title) : title }}
            <Tooltip
              v-if="tooltip"
              :text="tooltip" />
          </Heading>
        </template>

        <template #skeleton>
          <Heading
            v-skeleton="$te(title) ? $t(title) : title"
            level="2" />
        </template>
      </Skeleton>

      <slot />
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading';
import Skeleton from '@/components/Skeleton';
import Tooltip from '@/components/Tooltip';
import { cardVariant } from '@/service/tailwind/variants/cardVariant';
import { useTailwindProp } from '@/service/tailwind/useTailwindProp';

const {
  prop: variantProp,
  options: variantOptions,
} = useTailwindProp(cardVariant);

export default {
  name: 'Card',
  components: {
    Skeleton,
    Heading,
    Tooltip,
  },

  props: {
    variant: variantProp,

    title: {
      type: String,
      default: null,
    },

    tooltip: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
    },

    centered: {
      type: Boolean,
    },
  },

  computed: {
    /**
     * @returns {string|string[]}
     */
    variantClasses() {
      return variantOptions[this.variant];
    },
  },
};
</script>

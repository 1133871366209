/* eslint-disable jsdoc/no-undefined-types */

/**
 * @param {TailwindVariant} variant
 *
 * @returns {UseTailwindProp}
 */
export function useTailwindProp(variant) {
  const { name, options, propOptions } = variant;

  const validator = (value) => {
    const isValid = options.hasOwnProperty(value);
    if (!isValid) {
      // eslint-disable-next-line no-console,max-len,vue/max-len
      console.warn(`Key "${value}" is missing in Tailwind prop validator "${name}". Add this key to avoid related css getting purged in production mode.`);
    }
    return isValid;
  };

  const prop = {
    validator,
    ...propOptions,
  };

  return {
    prop,
    options,
  };
}

import { defineVariant } from '@/service/tailwind/defineVariant';

export const cardVariant = defineVariant(
  'cardVariant',
  {
    default: [
      'tw-bg-white',
      'tw-text-black',
    ],
    primary: [
      'tw-bg-primary-500',
      'tw-text-foreground',
    ],
    danger: [
      'tw-bg-danger-100',
      'tw-text-danger-900',
    ],
  },
  {
    default: 'default',
  },
);

<template>
  <span
    v-b-tooltip.right.hover.v-primary
    :title="$te(text) ? $t(text) : text">
    <i class="i-info small" />
  </span>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'Tooltip',
  directives: {
    BTooltip: VBTooltip,
  },

  props: {
    /**
     * Text to display in the tooltip. #tooltip slot can be used as an alternative.
     */
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<template>
  <div class="tw-relative">
    <Transition name="fade-absolute">
      <SkeletonLoader
        v-if="loading"
        class="tw-w-full"
        :centered="centered">
        <slot name="skeleton" />
      </SkeletonLoader>

      <div
        v-else
        :class="contentClass"
        class="tw-w-full">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'Skeleton',
  components: {
    SkeletonLoader,
  },

  props: {
    loading: {
      type: Boolean,
    },

    centered: {
      type: Boolean,
    },

    /**
     * Class(es) to add to the div that wraps the default slot.
     */
    contentClass: {
      type: [String, Array, Object],
      default: null,
    },
  },
};
</script>
